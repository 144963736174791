import React, { Suspense, lazy } from "react";
import { HashRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import ScrollToTop from './ScrollToTop.js';
import ProtectedRoute from './ProtectedRoute'; // Rutas protegidas
import './App.css';

// Carga de componentes de forma lazy
const Header = lazy(() => import('./Header.js'));
const Footer = lazy(() => import('./Footer.js'));
const MainPage = lazy(() => import('./MainPage.js'));
const Servicios = lazy(() => import('./Servicios.js'));
const Contacto = lazy(() => import('./Contacto.js'));
const PruebasElectricas = lazy(() => import('./PruebasElectricas.js'));
const VDF = lazy(() => import('./vdf.js'));
const Experiencia = lazy(() => import('./Experiencia.js'));
const SistemaPotencia = lazy(() => import('./SistemaPotencia.js'));
const BombeoHorizontal = lazy(() => import('./BombeoHorizontal.js'));
const SistemasComunicacion = lazy(() => import('./SistemasComunicacion.js'));
const Bec = lazy(() => import('./Bec.js'));
const Login = lazy(() => import('./login.js'));
const Register = lazy(() => import('./register.js'));
const Certificados = lazy(() => import('./certificados.js'));
const AdminDashboard = lazy(() => import('./AdminDashboard.js'));
const EditCertificados = lazy(() => import('./editCertificados.js'));
const ClientTables = lazy(() => import('./clientTables.js'));
const Visualizer = lazy(() => import('./visualizer.jsx'));
const EditFiles = lazy(() => import('./editfiles.js'));
const EquipmentTable = lazy(() => import('./api/Tablas/equipos.js'));

function AppContent() {
  const location = useLocation();
  // Verifica si la ruta actual es '/visualizer'
  const hideHeaderFooter = location.pathname === "/visualizer";

  return (
    <div className="Head-Content">
      {/* Renderiza Header solo si no es /visualizer */}
      {!hideHeaderFooter && <Header />}
      <div className="Content">
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/servicios" element={<Servicios />} />
          <Route path="/contacto" element={<Contacto />} />
          <Route path="/pruebas-electricas" element={<PruebasElectricas />} />
          <Route path="/vdf" element={<VDF />} />
          <Route path="/experiencia" element={<Experiencia />} />
          <Route path="/potencia-hidraulica" element={<SistemaPotencia />} />
          <Route path="/bombeo-horizontales" element={<BombeoHorizontal />} />
          <Route path="/sistemas-comunicacion" element={<SistemasComunicacion />} />
          <Route path="/bec" element={<Bec />} />
          <Route path="/login" element={<Login />} />
          <Route path="/prueba" element={<EditFiles />} />
          <Route path="/prueba2" element={<EquipmentTable />} />
          <Route
            path="/re-cer"
            element={
              <ProtectedRoute allowedTypes={['admin']}>
                <EditCertificados />
              </ProtectedRoute>
            }
          />
          <Route path="/visualizer" element={<Visualizer />} />
          <Route
            path="/register"
            element={
              <ProtectedRoute allowedTypes={['admin']}>
                <Register />
              </ProtectedRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedRoute allowedTypes={['admin']}>
                <AdminDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/certificados"
            element={
              <ProtectedRoute allowedTypes={['admin', 'user']}>
                <Certificados />
              </ProtectedRoute>
            }
          />
          <Route
            path="/client"
            element={
              <ProtectedRoute allowedTypes={['admin', 'client']}>
                <ClientTables />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
      {/* Renderiza Footer solo si no es /visualizer */}
      {!hideHeaderFooter && (
        <div className="Foot-Content">
          <Footer />
        </div>
      )}
    </div>
  );
}

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Suspense fallback={<div>Cargando...</div>}>
        <AppContent />
      </Suspense>
    </Router>
  );
}

export default App;
